// Generated by Framer (7f988a2)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {P2Vg02Ob3: {hover: true}};

const cycleOrder = ["P2Vg02Ob3"];

const serializationHash = "framer-yEOpV"

const variantClassNames = {P2Vg02Ob3: "framer-v-bqbuu5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "P2Vg02Ob3", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-bqbuu5", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"P2Vg02Ob3"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"P2Vg02Ob3-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0xlZmVyaSBCYXNlIFR5cGUgUmVndWxhcg==", "--framer-font-family": "\"Leferi Base Type Regular\", \"Leferi Base Type Regular Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(87, 87, 87))"}}>ABOUT</motion.p></React.Fragment>} className={"framer-1x7yeyj"} fonts={["CUSTOM;Leferi Base Type Regular"]} layoutDependency={layoutDependency} layoutId={"JlcT5I8w2"} style={{"--extracted-r6o4lv": "rgb(87, 87, 87)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"P2Vg02Ob3-hover": {children: <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0xlZmVyaSBCYXNlIFR5cGUgQm9sZA==", "--framer-font-family": "\"Leferi Base Type Bold\", \"Leferi Base Type Bold Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(87, 87, 87))"}}>ABOUT!</motion.p></React.Fragment>, fonts: ["CUSTOM;Leferi Base Type Bold"]}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yEOpV.framer-1heuxou, .framer-yEOpV .framer-1heuxou { display: block; }", ".framer-yEOpV.framer-bqbuu5 { cursor: pointer; height: 18px; overflow: visible; position: relative; width: 54px; }", ".framer-yEOpV .framer-1x7yeyj { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 54
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"IliWt26lB":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkwpOOkxOJ: React.ComponentType<Props> = withCSS(Component, css, "framer-yEOpV") as typeof Component;
export default FramerkwpOOkxOJ;

FramerkwpOOkxOJ.displayName = "ABOUT";

FramerkwpOOkxOJ.defaultProps = {height: 18, width: 54};

addFonts(FramerkwpOOkxOJ, [{explicitInter: true, fonts: [{family: "Leferi Base Type Regular", source: "custom", url: "https://framerusercontent.com/assets/9JKfYB9jGqE95DBnqnO7DoiS08.woff2"}, {family: "Leferi Base Type Bold", source: "custom", url: "https://framerusercontent.com/assets/UIwKQYOjGRfPEcT47T7ELr5bVs.woff2"}]}], {supportsExplicitInterCodegen: true})